// helpers
import bookingIsStatePreordered from "@cloudspire/legacy-resources/src/helpers/booking/isStatePreordered";
import bookingIsStatePromised from "@cloudspire/legacy-resources/src/helpers/booking/isStatePromised";
import bookingIsStateOpened from "@cloudspire/legacy-resources/src/helpers/booking/isStateOpened";
import bookingIsWorkflowNative from "@cloudspire/legacy-resources/src/helpers/booking/isWorkflowNative";
import bookingHasPaymentMethod from "@cloudspire/legacy-resources/src/helpers/booking/hasPaymentMethod";
import bookingIsOackStateRefused from "@cloudspire/legacy-resources/src/helpers/booking/isOackStateRefused";

/**
 * Retourne vrai si la page de paiement peut être affichée.
 * Algorithme :
 * SI l'état réservation est soit: PREORDERED, PROMISED ou OPENED
 * ET QUE le workflow de la réservation est NATIVE
 * ET QUE la réservation autorise au moins 1 moyen de paiement
 * ET QUE le propriétaire n'a pas refusé la demande de réservation.
 */
export default function bookingIsPayable({ booking }) {
  return (
    (bookingIsStatePreordered({ booking }) ||
      bookingIsStatePromised({ booking }) ||
      bookingIsStateOpened({ booking })) &&
    bookingIsWorkflowNative({ booking }) &&
    bookingHasPaymentMethod({ booking }) &&
    !bookingIsOackStateRefused({ booking })
  );
}
