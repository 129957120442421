// dependencies
import React, { useCallback } from "react";
import { useIntl } from "react-intl";

// components
import CheckBox from "@cloudspire/legacy-resources/src/components/CheckBox2";

// helpers
import {
  bookingGetNatureByForWork,
  bookingIsForWorkByNature,
} from "../../helpers/booking";

type PropsType = {
  name: string;
  value;
  mode?: "room" | "booking";
  checked?: boolean;
  checkboxProps?: any;
  onChange;
};

const CheckBoxForWork: React.FunctionComponent<PropsType> = (props) => {
  const { name, value, mode = "booking", checkboxProps, onChange } = props;

  const intl = useIntl();

  /**
   * Gère la modification du champ.
   */
  const handleChange = useCallback(
    (event) => {
      event.persist();

      const { checked } = event.target;

      if ("room" === mode) {
        onChange(event);

        return;
      }

      onChange({
        target: {
          name,
          value: bookingGetNatureByForWork({ forWork: checked, proOnly: true }),
        },
      });
    },
    [mode, name, onChange]
  );

  const checked =
    "room" === mode
      ? props.checked
      : bookingIsForWorkByNature({ nature: value });

  return (
    <CheckBox
      {...checkboxProps}
      name={name}
      id="formForWork"
      label={intl.formatMessage({
        defaultMessage: "Je voyage pour le travail",
      })}
      value={"room" === mode ? value : "1"}
      checked={checked}
      onChange={handleChange}
    />
  );
};

export default CheckBoxForWork;
